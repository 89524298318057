import React, { useContext } from "react";
import styles from "./ReviewLanding.module.scss";
import Button from "../../Common/Button/Button";
import Image from "next/image";
import homeStyles from "../../../styles/Home.module.scss";
import AppContext from "../../../context/AppContext";
import Link from "next/link";
function ReviewLanding() {
  const { contactForm, setContactForm }: any = useContext(AppContext);

  return (
    <div className={styles["reviews-landing-wrapper"]}>
      <div className={styles["reviews-landing-content"]}>
        <h2>
          Unlock Deeper Insights into{" "}
          <span className={homeStyles["span-gradient"]}>Buyer Trends </span>with
          our Review Monitoring Engine.
        </h2>
        <p>
          In a world where shoppers highly regard consumer reviews,
          understanding these insights becomes pivotal in decision-making.
          <br></br>
          <br></br>
          Socommerz’s Review Analyzers empowers Brands,CPG and eTailers to
          meticulously track and analyze customer reviews and ratings. Our
          Semantic Models equips you to refine your product and content
          strategy, driving higher conversions and customer satisfaction and
          alongside giving you a tracebility of your competitors.
        </p>
        <Link href="/trend-analyzer">
          <Button text="LEARN MORE" />
        </Link>
      </div>
      <div className={styles["reviews-landing-image"]}>
        <Image
          src={"/reviewrating/Product-intro.png"}
          alt=""
          width={400}
          height={400}
          className={styles["review-image"]}
        ></Image>
      </div>
    </div>
  );
}

export default ReviewLanding;
